import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { useIntl } from "react-intl"
import "@formatjs/intl-pluralrules/polyfill"


const NavbarLinks = (props) => {
	const intl = useIntl();

	return (
		<MainNav open={props.open}>
			<NavItem to={ intl.formatMessage({ id: 'cuinaLink'})}>
			  { intl.formatMessage({ id: 'cuinaLabel'})}
			</NavItem>
      <NavItem to={ intl.formatMessage({ id: 'cartaLink'})}>
        { intl.formatMessage({ id: 'cartaLabel'})}
      </NavItem>
			<NavItem to={ intl.formatMessage({ id: 'espaisLink'})}>
			  { intl.formatMessage({ id: 'espaisLabel'})}
			</NavItem>
      <NavItem to={ intl.formatMessage({ id: 'origensLink'})}>
        { intl.formatMessage({ id: 'origensLabel'})}
      </NavItem>
			<NavItem to={ intl.formatMessage({ id: 'noticiesLink'})}>
			  { intl.formatMessage({ id: 'noticiesLabel'})}
			</NavItem>
      <NavItem to={ intl.formatMessage({ id: 'contacteLink'})}>
        { intl.formatMessage({ id: 'contacteLabel'})}
      </NavItem>
		</MainNav>
	)
}

const MainNav = styled.div`
	display: flex;
  height: 100%;
  align-items: center;
	@media (max-width: 992px) {
    margin: 5vh 0;
	  flex-direction: column;
    transform: ${props => (props.open ? "inherit" : "translateY(-99999px)")};
    transition: all 0.2s ease-in-out;
    align-items: inherit;
	}
`

const NavItem = styled(Link)`
  text-decoration: none;
  color: white;
  display: inline-block;
  white-space: nowrap;
  margin: 0 2vw;
  transition: all 200ms ease-in;
  position: relative;

  :after {
    transition: all 0.4s ease-in;
  }

  :hover {
    color: rgba(51,49,50, 0.9);
    text-decoration: none;
    ::after {
      width: 100%;
    }
  }

  @media (max-width: 992px) {
    padding: 20px 0;
    font-size: 1.5rem;
    z-index: 6;
  }
`

export default NavbarLinks