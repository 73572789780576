import React, { useEffect, useState } from "react"
import styled from 'styled-components'
import NavbarLinks from "./NavbarLinks"
import LanguageSelector from './language-selector'
import Logo from "./Logo"


const Navbar = ({ siteTitle, lang, messages, pathname }) => {
  const [navbarOpen, setNavbarOpen] = useState(false);
  
  const [shouldShowNavbar, setShouldShowNavbar] = useState(true);
  const [scrollPos, setScrollPos] = useState(0);

  useEffect(() => {
    function handleScroll() {
      setScrollPos(document.body.getBoundingClientRect().top);
      setShouldShowNavbar(document.body.getBoundingClientRect().top > scrollPos);
    }

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });

  return (
    <Transition>
      <Navigation  className={shouldShowNavbar || navbarOpen ? "active" : "hidden"} >
        <Alignitor>
          <LogoWrapper>
            <Logo lang={lang} />
          </LogoWrapper>
          <Toggle
            navbarOpen={navbarOpen}
            onClick={() => setNavbarOpen(!navbarOpen)}
          >
            {navbarOpen ? <Hamburger open /> : <Hamburger />}
          </Toggle>
          {navbarOpen ? (
            <Navbox open>
              <NavbarLinks open/>
            </Navbox>
          ) : (
            <Navbox >
              <NavbarLinks/>
            </Navbox>
          )}
        </Alignitor>
      </Navigation>
    </Transition>
  )
}

const Transition = styled.div`
  .active {
    visibility: visible;
    transition: all 200ms ease-in;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

  }
  .hidden {
    @media (min-width: 992px) {
      visibility: hidden;
      transition: all 200ms ease-out;
      transform: translate(0, -100%);
    }
  }
  `

const Navigation = styled.nav`
  font-family: 'Caviar Dreams Bold', sans-serif;
  width: 100%;
  height: 14vh;
  display: flex;
  background-color: transparent;
  position: fixed;
  justify-content: space-between;
  text-transform: uppercase;
  z-index: 2;
  align-self: center;
  @media (max-width: 992px){
    background-color: rgba(51,49,50, 0.9);
    height: 7vh;
  }
  `
const Alignitor = styled.div`
  display: flex;
  width: 100%;
  height: 70%;
  justify-content: center;
  @media (max-width: 992px) {
    flex-direction: row;
    height: 100%;
  }
`

const Toggle = styled.div`
  display: none;
  height: 100%;
  margin-left: auto;
  cursor: pointer;
  padding: 0 10vw;

  @media (max-width: 992px) {
    display: flex;
  }
  `
const LogoWrapper = styled.div`
  background-color: rgba(51,49,50, 0.9);
  width: 10%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  @media (max-width: 992px) {
    background-color: transparent;
    width: 135px;
    margin-top: 15px;
  }
`

const Navbox = styled.div`
  background: rgba(165,148,130, 0.9);
  display: flex;
  flex-direction: column;
  @media (max-width: 992px) {
    position: fixed;
    width: 100%;
    transition: all 0.3s ease-in-out;
    height: ${props => (props.open ? "470px" : "0")};
    margin-top: ${props => (props.open ? "7vh" : "0")};
  }
  `

const Hamburger = styled.div`
  background-color: #FFF;
  width: 30px;
  height: 2px;
  transition: all .3s linear;
  align-self: center;
  position: relative;
  transform: ${props => (props.open ? "rotate(-45deg)" : "inherit")};

  ::before,
  ::after {
    width: 30px;
    height: 2px;
    background-color: #FFF;
    content: "";
    position: absolute;
    transition: all 0.3s linear;
  }

  ::before {
    transform: ${props =>
      props.open ? "rotate(-90deg) translate(-10px, 0px)" : "rotate(0deg)"};
    top: -10px;
  }

  ::after {
    opacity: ${props => (props.open ? "0" : "1")};
    transform: ${props => (props.open ? "rotate(90deg) " : "rotate(0deg)")};
    top: 10px;
  }
`

export default Navbar
