import PropTypes from "prop-types"
import React, { useState } from "react"
import { Location } from "@reach/router"
import { Link } from "gatsby"
import _ from "lodash"
import styled from 'styled-components'

function LanguageSelector({ lang, pathname, className }) {
  const [isListShown, setisListShown] = useState(false);
  const arrayPath = pathname.split('/');
  const hasLang = _.intersection(arrayPath, ["ca", "es", "en", "fr"]);
  const currentLang = _.join(hasLang).toUpperCase() || "CA";

  return (
    <LanguagesWrapper>
      <LanguageButton 
        onClick={() => setisListShown(!isListShown)}
        onMouseEnter={() => setisListShown(true)}
        onMouseLeave={() => setisListShown(false)}
      >
        {currentLang}
      </LanguageButton>
      {isListShown &&
        <LanguagesList
          onMouseEnter={() => setisListShown(true)}
          onMouseLeave={() => setisListShown(false)}   
        >
          { currentLang !== "CA" &&
            <LangItem 
              to={ (hasLang.length === 0) ? "" + pathname : pathname.replace(/\/\w*/,"")}
            >
              CA
            </LangItem>
          }
          { currentLang !== "ES" &&
            <LangItem
              to={ (hasLang.length === 0) ? "/es/" + pathname : pathname.replace(/\/\w*/,"/es")}
            >
              ES
            </LangItem>
          }
          { currentLang !== "EN" &&
            <LangItem
              to={ (hasLang.length === 0) ? "/en/" + pathname : pathname.replace(/\/\w*/,"/en")}
            >
              EN
            </LangItem>
          }
          { currentLang !== "FR" &&
            <LangItem
              to={ (hasLang.length === 0) ? "/fr/" + pathname : pathname.replace(/\/\w*/,"/fr")}
            >
              FR
            </LangItem>
          }
        </LanguagesList>
      }
    </LanguagesWrapper>
  )
}


const LanguageButton = styled.div`
  font-size: 16px;
  line-height: 60px;
  font-weight: 300;
  padding: 0;
  width: 60px;
  height: 60px;
  display: flex
  align-items: center;
  justify-content: center;
  color: #FFF;
  background-color: rgba(51,49,50, 0.9);
  cursor: pointer;
  @media (min-width: 992px){
    transform: rotate(90deg);
  }
`

const LanguagesList = styled.div`
  position: absolute;
  top: 0px;
  left: 60px;
  width: auto;
  height: auto;
  z-index: 4;
  background-color: rgba(51,49,50, 0.9);
  transition: all 0.3s ease-in-out;
  display: flex;
  padding: 0;
  margin: 0;
  @media (min-width: 992px) {
    a {
      transform: rotate(90deg);
    }
  }
  @media (max-width: 992px) {
    left: 0px;
    transform: translateY(-100%);
    flex-direction: column-reverse;
  }
`

const LanguagesWrapper = styled.div`
  display: flex;
  position: relative;
  width: 60px;
  height: 60px;
`

const LangItem = styled(Link)`
  text-decoration: none;
  padding: 21px;
  color: rgba(165,148,130, 0.9);
  :hover {
    color: white;
    text-decoration: none;
    ::after {
      width: 100%;
    }
  }
`

export default LanguageSelector