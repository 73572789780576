import { createGlobalStyle } from 'styled-components';

import CaviarDreamsFontWoff from './CaviarDreams.woff';
import CaviarDreamsBoldFontWoff from './CaviarDreams_Bold.woff';

export default createGlobalStyle`
    @font-face {
      font-family: 'Caviar Dreams';
      src: local('Caviar Dreams'),
      url(${CaviarDreamsFontWoff}) format('woff');
      font-weight: 300;
      font-style: normal;
    }
    @font-face {
      font-family: 'Caviar Dreams Bold';
      src: local('Caviar Dreams Bold'),
      url(${CaviarDreamsBoldFontWoff}) format('woff');
      font-weight: 300;
      font-style: bold;
    }
`;
