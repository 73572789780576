import React from "react"
import styled from 'styled-components'
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { getCurrentLangKey } from 'ptz-i18n'
import { IntlProvider } from "react-intl"
import "@formatjs/intl-pluralrules/polyfill"
import NavigationMenu from "../navbar/Navbar"
import NavbarLangReserves from "../navbar/NavbarLangReserves"
import Footer from "../footer"
import Image from "../../components/background-image"
import GlobalFonts from "../../fonts/fonts"

const Layout = ({ children, location, messages }) =>  {

	const data = useStaticQuery(graphql `
		query SiteTitleQuery {
			site {
				siteMetadata {
					languages {
						defaultLangKey
						langs
					}
				}
			}
		}
	`)

	const { langs, defaultLangKey } = data.site.siteMetadata.languages;
	const lang = getCurrentLangKey(langs, defaultLangKey, location.pathname);

	return (
		<IntlProvider locale={lang} messages={messages}>
		  <SiteWrapper>
		  	<GlobalFonts />
		    <NavigationMenu 
		    	siteTitle={"CAL PERE DEL MASET"} 
		    	lang={ lang } 
		    	messages={messages} 
		    	pathname={location.pathname} 
		    />
		    <NavbarLangReserves lang={ lang } pathname={location.pathname} />
		    <SiteContent lang={ lang }>{children}</SiteContent>
		    <Footer lang={ lang } messages={messages} />
		  </SiteWrapper>
		</IntlProvider>
	)
}

const SiteWrapper = styled.div`
	display: flex;
	min-height: 100vh;
	flex-direction: column;
	font-family: 'Caviar Dreams';
`

const SiteContent = styled.main`
	flex-grow: 1;
`

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.any.isRequired,
  messages: PropTypes.any.isRequired
}

export default Layout
