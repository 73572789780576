import React from "react"
import styled from "styled-components"
import Modal from 'react-modal';
import { useIntl } from "react-intl"
import "@formatjs/intl-pluralrules/polyfill"
import LanguageSelector from './language-selector'

const customStyles = {
  content : {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
};

Modal.setAppElement(`#___gatsby`);

const NavbarLangReserves = (props) => {
	const intl = useIntl();
  const [modalIsOpen, setIsOpen] = React.useState(false);
  function openModal() {
    setIsOpen(true);
  }

  const elTenedorUrl = {
    'ca': 'es_ES',
    'es': 'es_ES',
    'en': 'en_GB',
    'fr': 'fr_FR'
  };

  function closeModal(){
    setIsOpen(false);
  }

	return (
		<ReservationsLangWrapper>
      <ReservationsLang>
        <ReservationsWrapper>
    			<NavItem  onClick={openModal} >
    			  { intl.formatMessage({ id: 'reservesLabel'})}
    			</NavItem>
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
          >
          <CloseModalButton onClick={closeModal}>X</CloseModalButton>
          <iframe 
            src={`https://module.lafourchette.com/${elTenedorUrl[props.lang]}/module/56527-16ed8`}
            title="56527-16ed8"
            width="350" 
            height="500" 
            marginHeight="0" 
            scrolling="Yes" 
            frameBorder="0">
          </iframe>
          </Modal>
        </ReservationsWrapper>
        <LanguageSelector lang={props.lang} pathname={props.pathname} />
      </ReservationsLang>
		</ReservationsLangWrapper>
	)
}

const CloseModalButton = styled.button`
  right: 0;
  margin-right: 20px;
  position: absolute;
`

const ReservationsLangWrapper = styled.div`
  position: fixed;
  top: 50%;
  right: 0;
  width: auto;
  height: auto;
  display: flex;
  padding-top: 0px;
  z-index: 999999;
  transform: translateY(-50%);
  @media (max-width: 992px) {
    top: auto;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    width: 220px;
  }
`

const ReservationsLang = styled.div`
  background: rgba(165,148,130, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 238px;
  height: 60px;
  transform: rotate(-90deg) translateX(89px) translateY(-60px);
  transform-origin: top right;
  position: relative;
  @media (max-width: 992px) {
    transform: rotate(0deg);
  }
`

const ReservationsWrapper = styled.div`
  padding: 9px 32px;
  flex: 1 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
`


const NavItem = styled.a`
  text-decoration: none;
  color: white;
  display: inline-block;
  white-space: nowrap;
  margin: 0 1vw;
  transition: all 200ms ease-in;
  position: relative;
  font-size: 1.5rem;
  :after {
    transition: all 0.4s ease-in;
  }
  :hover {
    color: rgba(51,49,50, 0.9);
    text-decoration: none;
    ::after {
      width: 100%;
    }
  }
  @media (max-width: 992px) {
    padding: 20px 0;
    font-size: 1.5rem;
    z-index: 6;
  }
`

export default NavbarLangReserves