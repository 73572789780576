import React from "react"
import { StaticQuery, graphql } from "gatsby"
import styled from 'styled-components'
import Img from "gatsby-image/withIEPolyfill"

const BackgroundSection = (props) => (
  <StaticQuery
    query={graphql`
      query {
        vinyards: file(relativePath: { eq: "vinyards.jpg"}) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1200) {
              presentationWidth,
              presentationHeight,
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        casa_antiga: file(relativePath: { eq: "origens/casa_antiga.jpg"}) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1200) {
              presentationWidth,
              presentationHeight,
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        paisatge: file(relativePath: { eq: "origens/paisatge.jpg"}) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1200) {
              presentationWidth,
              presentationHeight,
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        espais: file(relativePath: { eq: "espais/principal_taula.jpg"}) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1200) {
              presentationWidth,
              presentationHeight,
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        cuina: file(relativePath: { eq: "cuina/plat_carxofes_hero.jpg"}) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1200) {
              presentationWidth,
              presentationHeight,
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        noticies: file(relativePath: { eq: "noticies/entrada_restaurant.jpg"}) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1200) {
              presentationWidth,
              presentationHeight,
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        contacte: file(relativePath: { eq: "contacte/finestra.jpg"}) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1200) {
              presentationWidth,
              presentationHeight,
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        carta: file(relativePath: { eq: "carta/arc.jpg"}) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1200) {
              presentationWidth,
              presentationHeight,
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      const imageData = data[props.image].childImageSharp.fluid
      return (
        <WrapperImage
          fluid={imageData}
          objectFit="cover"
          objectPosition="50% 50%"
          alt="Hero Image"
        >
        {props.children}
        </WrapperImage>
      )
    }}
  />
)

const WrapperImage = styled(Img)`
  @media (min-width: 992px){
    height: 700px;
  }
`

export default BackgroundSection
