import { Link } from "gatsby"
import React from "react"
import styled from 'styled-components'
import { useIntl } from "react-intl"
import FacebookIcon from "../images/footer/facebook.png"
import InstagramIcon from "../images/footer/instagram.png"
import TripadvisorIcon from "../images/footer/trip_advisor.png"
import TwitterIcon from "../images/footer/twitter.png"
import CalPereDelMasetLogo from "../images/footer/logo.png"

const Footer = ({ siteTitle, lang }) => {
  const intl = useIntl();

  return (  
    <StyledFooter>
      <StyledMainContent>
        <StyledFooterItem>
          <ul>
            <li>{ intl.formatMessage({ id: 'direccioCarrer'})}</li>
            <li>{ intl.formatMessage({ id: 'direccioPoblacio'})}</li>
            <li>
              <a 
                href="https://goo.gl/maps/3KNByWgPD9Pu7LKA8" 
                target="_blank" 
                rel="noreferrer noopener"
              >
              { intl.formatMessage({ id: 'labelGoogleMaps'})}
              </a>
            </li>
            <li>{ intl.formatMessage({ id: 'numTelefon'})}</li>
            <li>{ intl.formatMessage({ id: 'email'})}</li>
          </ul>
        </StyledFooterItem>
        <StyledFooterItem>
          <ul>
            <li>{ intl.formatMessage({ id: 'diesDinarOberts'})}</li>
            <li>{ intl.formatMessage({ id: 'horesDinarOberts'})}</li>
            <li>{ intl.formatMessage({ id: 'diesSoparOberts'})}</li>
            <li>{ intl.formatMessage({ id: 'horesSoparOberts'})}</li>
            <li>{ intl.formatMessage({ id: 'diaTancat'})}</li>
          </ul>
        </StyledFooterItem>
        <StyledFooterItem>
          <StyledSocialTitle>{ intl.formatMessage({ id: 'segueixnos'})}</StyledSocialTitle>
          <StyledSocialList>
            <li>
              <a 
                href="https://facebook.com/calperedelmaset" 
                target="_blank" 
                rel="noreferrer noopener"
              ><img 
                  src={FacebookIcon} 
                  alt="facebook icon"
                  loading="lazy"
                  width="37" 
                  height="37" /></a>
            </li>
            <li>
              <a 
                href="https://twitter.com/calperedelmaset" 
                target="_blank" 
                rel="noreferrer noopener"
              ><img 
                src={TwitterIcon} 
                alt="twitter icon"
                loading="lazy"
                width="37" 
                height="37" /></a>
            </li>
            <li>
              <a 
                href="https://www.instagram.com/calperedelmaset/" 
                target="_blank" 
                rel="noreferrer noopener"
              ><img 
                src={InstagramIcon} 
                alt="instagram icon"
                loading="lazy" 
                width="37" 
                height="37" /></a>
            </li>
            <li>
              <a 
                href="https://www.tripadvisor.com/Restaurant_Review-g793622-d996251-Reviews-Cal_Pere_del_Maset-Sant_Pau_d_Ordal_Subirats_Catalonia.html" 
                target="_blank" 
                rel="noreferrer noopener"
              ><img 
                src={TripadvisorIcon} 
                alt="tripadvisor icon"
                loading="lazy"
                width="37" 
                height="37" /></a>
            </li>
          </StyledSocialList>
        </StyledFooterItem>
        <StyledFooterItem>
          <ul>
            <li>
              <a href="mailto:info@calperedelmaset.com">{ intl.formatMessage({ id: 'treballaAmbNosaltres'})}</a>
            </li>
          </ul>
        </StyledFooterItem>
        <StyledFooterItem>
          <img src={CalPereDelMasetLogo} alt="Cal Pere del Maset logo" loading="lazy" />
        </StyledFooterItem>
      </StyledMainContent>
    </StyledFooter>
  )
}


//<StyledFooterPrivacy>
 // <StyledPrivacyList>
  //  <li><a href="#">{ intl.formatMessage({ id: 'privacitat'})}</a></li>
   // <li><a href="#">{ intl.formatMessage({ id: 'condicionsDus'})}</a></li>
   // <li><span>© {new Date().getFullYear()} 37674023T</span></li>
  //</StyledPrivacyList>
//</StyledFooterPrivacy>

const StyledFooter = styled.footer`
  font-family: 'Caviar Dreams', sans-serif;
  font-size: 11px;
  a {
    text-decoration: none;
    color: #eee;
  }
  a:hover {
    text-decoration: underline;
  }
  ul {
    list-style: none;
    padding-left: 0;
  }
`

const StyledMainContent = styled.section`
 background-color: #231F20;
 opacity: 0.9;
 display: flex;
 flex-wrap: wrap;
 @media only screen and (min-width: 29.8125rem /* 477px */) {
    justify-content: space-evenly;
 }
 @media only screen and (min-width: 77.5rem /* 1240px */) {
    justify-content: space-evenly;
 }
`

const StyledFooterItem = styled.div`
  color: #B7A89A;
  padding: 1rem;
  min-width: 12.5rem;
  ul li{ 
    margin:0 5px 10px 0; 
    vertical-align:center
  }
  @media (max-width:  992px){
    margin-bottom: 50px
  }
`

const StyledSocialTitle = styled.h2`
  color: #B7A89A;
  text-align: center;
`

const StyledSocialList = styled.ul`
  display: flex;
  justify-content: center;
  li {
    margin: 0.5rem;
    font-size: 1.25rem;
  }
`

const StyledFooterPrivacy = styled.section`
  height: 24px;
  color:  #231F20;
  text-transform: uppercase;
  display: flex;
  flex-wrap: wrap;
  font-size: 75%;
  background-color: #B7A89A;
  padding-bottom: 4px;
  > a:link, a:visited {
    color: #87796a;
  }
`

const StyledPrivacyList = styled.ul`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  li {
    margin: 0.125rem 0.625rem;
    white-space: nowrap;
  }
`

export default Footer

// info@calperedelmaset.com