import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { Link, useStaticQuery, graphql } from "gatsby"

const Logo = (props) => {
	const data = useStaticQuery(graphql`
		query {
			file(name: { eq: "imgLogoCalPere"}, extension: { eq: "png" }) {
				childImageSharp {
					fluid(maxWidth: 213 pngQuality: 100) {
						...GatsbyImageSharpFluid
					}
				}
			}
		}
	`)

	const to = (props.lang === 'ca') ? "" : props.lang;

	return (
		<LogoWrap>
			<Link to={`/${to}`}>
				<Img fluid={data.file.childImageSharp.fluid} alt="Cal Pere del Maset logo" />
			</Link>
		</LogoWrap>
	)
}


const LogoWrap = styled.div`
	height: 100%;
	padding: 10px;
	@media (min-width:  2000px){
		height: 100%;
	}
	@media (max-width:  1999px) and (min-width:  1750px){
		height: 95%;
	}
	@media (max-width:  1749px) and (min-width:  1500px){
		height: 90%;
	}
	@media (max-width:  1499px) and (min-width:  1025px){
		height: 88%;
	}
	@media (max-width:  1024px) and (min-width:  993px){
		height: 75%;
	}
  @media (max-width:  992px){
  }
`

export default Logo

// @media (min-width: 1025px){
// }
// @media (max-width: 1024px) and (min-width: 541px){
// }
// @media (max-width: 540px){
// }